import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import CountUp from 'react-countup';
import { pxToRem, breakpoint } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import { assetLink } from 'src/utils';
import { useIsVisible } from 'src/hooks';

const useStyles = createUseStyles({
  container: {
    background: 'linear-gradient(119.13deg, #13266D 47.32%, #5FBCFF 163.26%)',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(291.63deg, #13266D -10.91%, #244BA3 89.17%)',
    },
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    marginTop: pxToRem(50),
  },
  statContainer: {
    padding: '0 1vw',
    width: '20%',
    [breakpoint.down('sm')]: {
      width: '50%',
      marginBottom: pxToRem(30),
    },
  },
  shape: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: -100,
    zIndex: -1,
    mixBlendMode: 'soft-light',
    [breakpoint.down('sm')]: {
      height: '100%',
      left: 0,
      objectFit: 'cover',
    },
  },
});

const stats: {
  number: number,
  description: string,
  isPercentage?: boolean,
}[] = [
  {
    number: 78,
    description: 'Organic new customer growth for Cinclips.',
    isPercentage: true,
  },
  {
    number: 82,
    description: 'Hours of work saved per month for Boat Rentals In The Keys.',
  },
  {
    number: 50,
    description: 'Cost reduction for Mi Casa Cal-Mex Cantina.',
    isPercentage: true,
  },
  {
    number: 100,
    description: 'Positive reviews for Chapala Iron.',
    isPercentage: true,
  },
];

const BusinessIsBetter: React.FC = () => {
  const classes = useStyles();
  const statsRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(statsRef);
  const [isSeen, setIsSeen] = useState(false);

  useEffect(() => {
    if (!isSeen && isVisible) {
      setIsSeen(true);
    }
  }, [isSeen, isVisible]);

  return (
    <SectionContainer className={classes.container}>
      <img src={assetLink('landing-ui/background-shapes/3.svg')} alt="" className={classes.shape} />
      <Typography variant="h2" color="white" centered underline>
        Business is Better with Bizwise
      </Typography>
      <div className={classes.statsContainer}>
        {stats.map(({ number, description, isPercentage }) => (
          <div ref={statsRef} className={classes.statContainer} key={number + description}>
            <Typography variant="h2" color="teal" centered>
              {isSeen && <CountUp end={number} duration={1} suffix={isPercentage ? '%' : ''} />}
            </Typography>
            <Typography variant="p3" color="white" centered>
              {description}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default BusinessIsBetter;
