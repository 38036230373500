import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import HeroSection from 'src/sections/Customers/Hero';
import BusinessIsBetter from 'src/sections/Customers/BusinessIsBetter';
import CustomerSpotlights from 'src/sections/Customers/CustomerSpotlights';
import TestimonialsSection from 'src/sections/shared/Testimonials';
import GetStarted from 'src/sections/Customers/GetStarted';
import spotlights from 'src/sections/Customers/spotlights';
import ApiWrapper from 'src/components/ApiWrapper';

const CustomersPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Our Customers - Bizwise" />
      <HeroSection />
      <CustomerSpotlights spotlights={spotlights.slice(0, 6)} heading="What happens when we take the lead" />
      <BusinessIsBetter />
      <CustomerSpotlights spotlights={spotlights.slice(6)} heading="Look what Bizwise has for your business" />
      <TestimonialsSection isLight={false} showBackgroundImage={false} />
      <GetStarted />
    </Layout>
  </ApiWrapper>
);

export default CustomersPage;
