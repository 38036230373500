import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint, spacing } from 'src/theme';
import SlantedSection from 'src/sections/shared/SlantedSection';
import Typography from 'src/components/core/Typography';
import { SectionImages } from '../shared/StaticImages';

const useStyles = createUseStyles({
  container: {
    height: pxToRem(650),
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    padding: `${spacing(20)} ${spacing(8)}`,
    marginLeft: 'auto',
    marginRight: 'auto',
    [breakpoint.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
      paddingTop: spacing(2),
      paddingBottom: spacing(15),
    },
    [breakpoint.down('sm')]: {
      paddingLeft: spacing(6),
      paddingTop: spacing(2),
      paddingBottom: spacing(15),
    },
  },
  contentBackground: {
    background: 'linear-gradient(119.13deg, #13266D 47.32%, #5FBCFF 163.26%)',
    [breakpoint.down('sm')]: {
      background: 'linear-gradient(98.19deg, #13266D -14.76%, #5FBCFF 293.67%), #13296B',
    },
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <SlantedSection
      img={SectionImages[13]}
      contentWidth={0.4}
      className={classes.container}
      showNavbarGradient
      backgroundShape={5}
      contentClassName={classes.contentBackground}
    >
      <div className={classes.content}>
        <Typography variant="h2" color="white">
          Customers
        </Typography>
        <Typography variant="p1" color="white" style={{ width: pxToRem(268) }}>
          Success stories?
          {' '}
          We have plenty!
          {' '}
          Months ago, they were in your shoes.
        </Typography>
      </div>
    </SlantedSection>
  );
};

export default Hero;
