import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: spacing(10),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
});

const GetStarted: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer
      className={classes.container}
      backgroundShape={4}
      background="light-blue"
      backgroundShapeBlendMode="soft-light"
    >
      <Typography variant="h2" color="blue" underline centered>
        Thousands of businesses love Bizwise.
        <br />
        We know you will too.
      </Typography>
      <Typography variant="p1" color="blackish" centered style={{ marginTop: spacing(10) }}>
        Get started for free — no credit card required.
      </Typography>
      <Button
        variant="filled"
        color="teal"
        href="/register/"
        className={classes.button}
      >
        Get started
      </Button>
    </SectionContainer>
  );
};

export default GetStarted;
