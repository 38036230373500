import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem, breakpoint } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import { Spotlight } from './spotlights';

const useStyles = createUseStyles({
  container: {
    [breakpoint.down('sm')]: {
      padding: '8vw 0',
    },
  },
  subtitle: {
    [breakpoint.down('sm')]: {
      padding: '0 4vw',
    },
  },
  spotlights: {
    marginTop: pxToRem(32),
    display: 'flex',
    flexWrap: 'wrap',
  },
  spotlightsContainer: {
    width: '50%',
    padding: pxToRem(15),
    marginBottom: pxToRem(65),
    [breakpoint.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  spotlightsTextContainer: {
    [breakpoint.down('sm')]: {
      padding: '0 4vw',
    },
  },
  spotlightImg: {
    marginBottom: pxToRem(32),
    width: '100%',
  },
  businessName: {
    marginBottom: pxToRem(17),
  },
  businessDescription: {
    maxWidth: '75%',
    [breakpoint.down('sm')]: {
      maxWidth: '100%',
    },
  },
});

interface Props {
  spotlights: Spotlight[],
  heading: string,
}

const CustomerSpotlights: React.FC<Props> = ({ spotlights, heading }) => {
  const classes = useStyles();
  const [focusedBusinessName, setFocusedBusinessName] = React.useState<string | null>(null);
  return (
    <SectionContainer className={classes.container}>
      <Typography className={classes.subtitle} variant="h2" color="blue" centered underline>
        {heading}
      </Typography>
      <div className={classes.spotlights}>
        {spotlights.map(({
          businessName, img, poster, videoSrc,
        }) => {
          const showVideo = Boolean(focusedBusinessName === businessName && videoSrc);
          return (
            <div className={classes.spotlightsContainer} key={businessName}>
              {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
              <div
                className="mb-8 relative"
                onMouseOver={() => setFocusedBusinessName(businessName)}
                onMouseLeave={() => setFocusedBusinessName((old) => (old === businessName
                  ? null : old))}
              >
                {img}
                {showVideo && videoSrc && (
                  <video
                    src={videoSrc}
                    muted
                    autoPlay
                    poster={poster}
                    loop
                    controls={false}
                    playsInline
                    disableRemotePlayback
                    className="absolute top-0 left-0 w-full h-full object-cover min-w-full max-w-full"
                  />
                )}
              </div>
              <div className={classes.spotlightsTextContainer}>
                <Typography variant="h3" color="blue" className={classes.businessName}>
                  {businessName}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </SectionContainer>
  );
};

export default CustomerSpotlights;
